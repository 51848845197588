var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "주요 소장품 소개",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-collection-detail', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/collection/major/collection03.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "name",
      fn: function () {
        return [_vm._v("새마을금고 정관")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("회원, 총회와 이사회, 임원과 직원, 업무, 사업의 집행, 회계 등의 내용이 담겨 있는 경남 진주시 대안동 중앙시장 새마을금고 정관")];
      },
      proxy: true
    }, {
      key: "year",
      fn: function () {
        return [_vm._v(" 1978년 ")];
      },
      proxy: true
    }, {
      key: "material",
      fn: function () {
        return [_vm._v(" 종이 ")];
      },
      proxy: true
    }, {
      key: "size",
      fn: function () {
        return [_vm._v("가로 18.8cm, 세로 26.7cm")];
      },
      proxy: true
    }, {
      key: "hall",
      fn: function () {
        return [_vm._v(" 제1전시관 ")];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }