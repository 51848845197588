var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "교육 · 행사",
            "tabActive": "교육 안내 및 소개",
            "bg": "/images/sub/program/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("교육 안내 및 소개")])]), _c('sub-tit-txt', {
    attrs: {
      "txt": "MG새마을금고역사관은 사회공헌활동의 일환으로 어린이, 청소년 대상 화폐, 용돈 관리 등을 배울 수 있는 교육·체험 프로그램을 제공하고, 금융소외계층 대상 금융교육, 보이스피싱 예방교육 등을 실시하여 지역민과 함께하는 금융을 적극 실천하고 있습니다."
    }
  }), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/sub/program/education/img.png",
      "alt": ""
    }
  })], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("교육 안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', {
    staticClass: "px-0 d-flex align-center"
  }, [_c('div', {
    staticClass: "icon-box",
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "120",
      "src": "/images/sub/program/education/child-icon.png",
      "alt": ""
    }
  }), _c('h4', {
    staticClass: "tit tit--sm mt-6 mt-md-8 mt-lg-10"
  }, [_vm._v("어린이 금융 교육")])], 1), _c('p', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text line-height-17"
  }, [_vm._v("성장기 유아 및 어린이들이 돈의 개념, 은행의 역할, 용돈 관리 등을 배울 수 있는 금융 교육 프로그램입니다. 전문 강사들이 유아 및 어린이 대상 맞춤형 교육을 진행하여, 다양한 체험을 통해 쉽고 재미있게 금융에 접근하도록 도와줍니다.")])]), _c('li', {
    staticClass: "px-0 d-flex align-center"
  }, [_c('div', {
    staticClass: "icon-box",
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "120",
      "src": "/images/sub/program/education/teenager-icon.png",
      "alt": ""
    }
  }), _c('h4', {
    staticClass: "tit tit--sm mt-6 mt-md-8 mt-lg-10"
  }, [_vm._v("청소년 금융 교육")])], 1), _c('p', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text line-height-17"
  }, [_vm._v("본 교육은 용돈 씀씀이가 커지는 청소년이 알아야 할 합리적인 용돈 사용법, 저축 및 투자, 신용점수 등의 금융 지식을 제공합니다. 또한 미래를 준비하고 꿈을 향해 전진해야 하는 청소년 시기에 도움이 될 수 있는 금융·경제·진로 교육을 진행합니다.")])]), _c('li', {
    staticClass: "px-0 d-flex align-center"
  }, [_c('div', {
    staticClass: "icon-box",
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "120",
      "src": "/images/sub/program/education/senior-icon.png",
      "alt": ""
    }
  }), _c('h4', {
    staticClass: "tit tit--sm mt-6 mt-md-8 mt-lg-10"
  }, [_vm._v("시니어 금융 교육")])], 1), _c('p', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text line-height-17"
  }, [_vm._v("전문 강사가 다양한 시청각 자료를 활용하여 시니어를 대상으로 보이스피싱 예방교육을 선보입니다. 이에 더해 모바일 뱅킹 등 디지털 금융과 노후대비 금융상품을 소개하는 금융·경제 지식 관련 교육 또한 실시하고 있습니다.")])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }