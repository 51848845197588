var render = function render(){
  var _vm$board;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소개",
            "tabActive": "언론보도",
            "bg": "/images/sub/introduction/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("작성일: " + _vm._s(_vm.$dayjs(_vm.board.createdAt).format("YYYY-MM-DD")))])])]), (_vm$board = _vm.board) !== null && _vm$board !== void 0 && _vm$board.upload ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.attachments")) + " : "), _c('v-icon', [_vm._v("mdi-paperclip")]), _c('a', {
    staticStyle: {
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        _vm.download(_vm.board.files[0], _vm.board.files[0].name.split('/').pop());
      }
    }
  }, [_vm._v(_vm._s(_vm.board.files[0].fileName))])], 1)]) : _vm._e(), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content)
    }
  })])])]), _c('div', {
    staticClass: "mt-24px"
  }, [_c('div', {
    staticClass: "v-btn--group d-flex justify-end"
  }, [_c('v-btn', {
    attrs: {
      "color": "grey lighten-3",
      "tile": "",
      "outlined": "",
      "large": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text"
  }, [_vm._v("리스트")])])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }