<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <sub-collection-detail>
                <template #image>
                    <v-img src="/images/sub/collection/major/collection03.jpg" alt="" />
                </template>
                <template #name>새마을금고 정관</template>
                <template #txt>회원, 총회와 이사회, 임원과 직원, 업무, 사업의 집행, 회계 등의 내용이 담겨 있는 경남 진주시 대안동 중앙시장 새마을금고 정관</template>
                <template #year> 1978년 </template>
                <template #material> 종이 </template>
                <template #size>가로 18.8cm, 세로 26.7cm</template>
                <template #hall> 제1전시관 </template>
            </sub-collection-detail>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCollectionDetail from "@/components/client/sub/sub-collection-detail.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCollectionDetail,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
