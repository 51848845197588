<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="전시" tabActive="상설전시" bg="/images/sub/exhibition/sub-visual.jpg" />
        </template>
        <sub-section class="pb-0">
            <sub-exhibition-tab tabActive="3" />
            <sub-ci-title tit="앞으로 100년" />
            <sub-tit-txt
                txt="대한민국 대표 리딩 금융협동조합 MG새마을금고. MG새마을금고는 지역사회를 넘어 국경을 초월한 상생과 나눔을 실천하며
앞으로의 100년을 준비하고 있습니다. 공동체 정신을 바탕으로 반세기를 굳건히 지켜냈듯이, 글로벌 금융 리더로서의 미래를
내다볼 수 있는 자리가 되었으면 합니다." />

            <div class="exhibition-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-1.jpg" alt="제3전시관 전경"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-2.jpg" alt="세계로 향하는 MG새마을금고"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-3.jpg" alt="제3전시관 전시물"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-4.jpg" alt="제3전시관 전시물"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-5.jpg" alt="MG새마을금고 클래스"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide3-6.jpg" alt="MG선진경영인"/>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-l.png" alt="이전 슬라이드" />
                </div>
                <div class="swiper-button-next" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-r.png" alt="다음 슬라이드" />
                </div>
                <div class="swiper-pagination" />
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCiTitle from "@/components/client/sub/sub-ci-title.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import SubExhibitionTab from "@/components/client/sub/sub-exhibition-tab.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import ImageBoardSecondary from "@/components/dumb/image-board-secondary.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCiTitle,
        SubExhibitionTab,
        SubTitTxt,
        UTabFilled,
        ImageBoardSecondary,
        Swiper,
        SwiperSlide,
    },
    data: () => {
        return {
            attrs_tabs_common,
            tab: null,

            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                speed: 800,
                loop: true,
                loopAdditionalSlides : 1,
                pagination: {
                    el: ".exhibition-slide .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".exhibition-slide .swiper-button-next",
                    prevEl: ".exhibition-slide .swiper-button-prev",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
