var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소개",
            "tabActive": "공지사항",
            "bg": "/images/sub/introduction/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("공지사항")])]), !_vm.$route.params._notice ? _c('notification-list') : _c('notification-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }