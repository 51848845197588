<template>
    <v-card tile flat @click="$emit('click')" class="image-board-card v-card--none-active-bg">
        <v-card tile flat class="image-board-card__img">
            <slot v-if="this.$slots['image']" name="image" />
        </v-card>

        <div class="pa-18px pa-md-24px">
            <div class="mb-4px mb-md-10px">
                <div class="font-size-18 font-size-md-20 text-truncate-2">
                    <slot name="subject" />
                </div>
            </div>

            <slot />
        </div>
    </v-card>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-board-card {
    display: block;
    border: 1px solid var(--v-grey-lighten3);
    &__img {
        transition: all 0.3s;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            opacity: 0;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
    }
    &:hover {
        .image-board-card {
            &__img {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}
</style>
