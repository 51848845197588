<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="관람안내" tabActive="시설안내" bg="/images/sub/information/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">시설안내</h2>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">건물평면도</h3>
                </div>
                <v-row class="row--sm">
                    <v-col cols="12" md="6">
                        <v-img class="border-light" src="/images/sub/information/facility/12f.jpg" alt="1, 2층 건물평면도 : 강당, 제1전시관, 제2전시관, 무인카페, 화장실, 수유실, 안내데스크가 있습니다. 안내데스크는 출입구를 기준으로 오른쪽에 있습니다." />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-img class="border-light" src="/images/sub/information/facility/3f.jpg" alt="3층 건물평면도 : 옥상정원, 제3전시관이 있습니다. 제3전시관 안쪽에 주제영상관이 있습니다." />
                    </v-col>
                </v-row>
            </div>

            <div class="sub-cont">
                <v-tabs class="v-tabs--density-compact" v-model="tab" v-bind="{ ...attrs_tabs_common, ...$attrs }">
                    <u-tab-filled class="w-20">강당</u-tab-filled>
                    <u-tab-filled class="w-20">무인카페</u-tab-filled>
                    <u-tab-filled class="w-20">1전시관</u-tab-filled>
                    <u-tab-filled class="w-20">2전시관</u-tab-filled>
                    <u-tab-filled class="w-20">3전시관</u-tab-filled>
                </v-tabs>
                <div class="mt-30 mt-md-44 mt-lg-60">
                    <v-tabs-items v-model="tab">
                        <v-tab-item eager :transition=false>
                            <img src="/images/sub/information/facility/1.jpg" alt="강당 사진" />
                            <!-- <v-img eager src="/images/sub/information/facility/1.jpg" alt="강당 사진" /> -->
                        </v-tab-item>

                        <v-tab-item eager :transition=false>
                            <img src="/images/sub/information/facility/2.jpg" alt="무인카페 사진" />
                            <!-- <v-img eager src="/images/sub/information/facility/2.jpg" alt="무인카페 사진" /> -->
                        </v-tab-item>

                        <v-tab-item eager :transition=false>
                            <img src="/images/sub/information/facility/3.jpg" alt="제1전시관 사진" />
                            <!-- <v-img eager src="/images/sub/information/facility/3.jpg" alt="제1전시관 사진" /> -->
                        </v-tab-item>

                        <v-tab-item eager :transition=false>
                            <img src="/images/sub/information/facility/4.jpg" alt="제2전시관 사진" />
                            <!-- <v-img eager src="/images/sub/information/facility/4.jpg" alt="제2전시관 사진" /> -->
                        </v-tab-item>

                        <v-tab-item eager :transition=false>
                            <img src="/images/sub/information/facility/5.jpg" alt="제3전시관 사진" />
                            <!-- <v-img eager src="/images/sub/information/facility/5.jpg" alt="제3전시관 사진" /> -->
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        UTabFilled,
    },
    data: () => {
        return {
            attrs_tabs_common,
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
