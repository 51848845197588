var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "image-board-card",
    attrs: {
      "to": _vm.to
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-card', {
    staticClass: "image-board-card__img",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [this.$slots['image'] ? _vm._t("image") : _vm._e()], 2), _c('div', {
    staticClass: "py-18px py-md-24px"
  }, [_c('div', {
    staticClass: "tit-box d-flex justify-center align-center text-truncate-2 text-center page-text page-text--lg"
  }, [_vm._t("subject")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }