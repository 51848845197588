<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="전시" tabActive="VR체험관(360°)" bg="/images/sub/exhibition/sub-visual.jpg" />
        </template>
        <sub-section>
            <div class="sub-cont">
                <sub-ci-title tit="MG새마을금고역사관 VR" />
                <p class="page-text page-text--lg grey--text text-center">
                    MG새마을금고역사관의 상설전시와 특별전시를 VR(가상현실)로 관람할 수 있습니다.
                </p>
                <div align="center" class="mt-24">
                    <v-img max-width="952" src="/images/sub/exhibition/online/online.png" alt=""/>
                </div>
                <div align="center" class="mt-40 mt-md-60 mt-lg-80">
                    <v-btn tile x-large color="primary" to="/res/vr" target="_blank">
                        VR 바로가기
                        <icon-chevron size="large" direction="right" class="ml-10"/>
                    </v-btn>
                </div>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCiTitle from "@/components/client/sub/sub-ci-title.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";

export default {
    props: {}, 
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCiTitle,
        SubTitTxt,
        IconChevron,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
