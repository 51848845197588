var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "주요 소장품 소개",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("주요 소장품 소개")])]), _c('v-row', {
    staticClass: "row--sm mt-12 mt-md-24"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/1"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb01.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 운영대장 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/2"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb02.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 연합회 회원가입증 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/3"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb03.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 정관 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/4"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb04.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 등록증 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/5"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb05.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 규정집 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/6"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb06.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 기본교재 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/7"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb07.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 관리요원반 교재 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/8"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb08.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 교육수료기념(수료생 명부) ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/9"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb09.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 안전기금 가입증서 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/10"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb10.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_c('span', [_vm._v(" 회계기(NCR"), _c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("社")]), _vm._v(") ")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/11"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb11.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 자기앞수표 발행기념"), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v(" 순금수표 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/12"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb12.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 1960년대 통장 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/13"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb13.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 마을금고 운영수기집 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/14"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb14.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 월간 새마을금고 창간호 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/15"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb15.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 사랑의 좀도리운동"), _c('br', {
          staticClass: "d-none d-md-block"
        }), _vm._v(" 캠페인 저금통 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-tertiary', {
    attrs: {
      "to": "/collection/major/16"
    },
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          staticClass: "grey lighten-5",
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/collection/major/thumb16.jpg"
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 저축 3조원 달성 포스터 ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }