var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "주요 소장품 소개",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-collection-detail', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/collection/major/collection01.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "name",
      fn: function () {
        return [_vm._v("마을금고 운영대장 ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v(" 1969년 경북 예천군 보문면 미호2리 재산 및 마을금고의 운영 관리 대장 ")];
      },
      proxy: true
    }, {
      key: "year",
      fn: function () {
        return [_vm._v(" 1969년 ")];
      },
      proxy: true
    }, {
      key: "material",
      fn: function () {
        return [_vm._v(" 종이 ")];
      },
      proxy: true
    }, {
      key: "size",
      fn: function () {
        return [_vm._v(" 가로 8cm, 세로 26cm ")];
      },
      proxy: true
    }, {
      key: "hall",
      fn: function () {
        return [_vm._v(" 제1전시관 ")];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }