<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <sub-collection-detail>
                <template #image>
                    <v-img src="/images/sub/collection/major/collection08.jpg" alt="" />
                </template>
                <template #name>교육수료기념(수료생 명부)</template>
                <template #txt>1988년도 교육 수료생 명부 및 교육 과정 모습 수록</template>
                <template #year>1976년</template>
                <template #material>종이</template>
                <template #size>가로 26cm, 세로 19cm</template>
                <template #hall>제1전시관</template>
            </sub-collection-detail>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCollectionDetail from "@/components/client/sub/sub-collection-detail.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCollectionDetail,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>