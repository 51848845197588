var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "ma-auto",
    attrs: {
      "max-width": "996"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg line-height-17 text-lg-justify grey--text"
  }, [_vm._v(" " + _vm._s(_vm.txt) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }