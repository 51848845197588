var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "전시",
            "tabActive": "VR체험관(360°)",
            "bg": "/images/sub/exhibition/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "sub-cont"
  }, [_c('sub-ci-title', {
    attrs: {
      "tit": "MG새마을금고역사관 VR"
    }
  }), _c('p', {
    staticClass: "page-text page-text--lg grey--text text-center"
  }, [_vm._v(" MG새마을금고역사관의 상설전시와 특별전시를 VR(가상현실)로 관람할 수 있습니다. ")]), _c('div', {
    staticClass: "mt-24",
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "952",
      "src": "/images/sub/exhibition/online/online.png",
      "alt": ""
    }
  })], 1), _c('div', {
    staticClass: "mt-40 mt-md-60 mt-lg-80",
    attrs: {
      "align": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "x-large": "",
      "color": "primary",
      "to": "/res/vr",
      "target": "_blank"
    }
  }, [_vm._v(" VR 바로가기 "), _c('icon-chevron', {
    staticClass: "ml-10",
    attrs: {
      "size": "large",
      "direction": "right"
    }
  })], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }