var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "select-box",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-100px h-40px",
    attrs: {
      "items": _vm.searchKeys,
      "outlined": "",
      "hide-details": "",
      "placeholder": "제목",
      "light": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "search-box",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "light": ""
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    staticClass: "search-btn",
    attrs: {
      "cols": "auto"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('v-btn', {
    attrs: {
      "dark": "",
      "color": "grey"
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('table', {
    staticClass: "board-list w-100 mt-12 mt-md-24 tit tit--sm"
  }, [_vm._m(0), _c('tbody', [_vm._l(_vm.fixed, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td', {
      staticClass: "board-list__txt d-none d-lg-table-cell pa-0",
      attrs: {
        "align": "center"
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")])], 1), _c('td', {
      staticClass: "board-list__txt subject"
    }, [_vm._v(" " + _vm._s(notification.subject) + " ")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v("관리자")]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(notification === null || notification === void 0 ? void 0 : notification.createdAt).format("YYYY-MM-DD")) + " ")])]);
  }), _vm._l(_vm.notifications, function (notification, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${notification._id}`);
        }
      }
    }, [_c('td'), _c('td', {
      staticClass: "board-list__txt subject"
    }, [_c('div', {
      staticClass: "ellip"
    }, [_c('span', [_vm._v(" " + _vm._s(notification.subject) + " ")])])]), _vm._m(1, true), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(notification === null || notification === void 0 ? void 0 : notification.createdAt).format("YYYY-MM-DD")) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "11"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "2%"
    }
  }), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "66%"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "16%"
    }
  }, [_vm._v("작성자")]), _c('th', {
    staticClass: "board-list__tit",
    staticStyle: {
      "width": "16%"
    }
  }, [_vm._v("작성일")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "ellip"
  }, [_c('span', [_vm._v("관리자")])])]);

}]

export { render, staticRenderFns }