<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="전시" tabActive="특별전시" bg="/images/sub/exhibition/sub-visual.jpg" />
        </template>
        <sub-section>
            <div class="sub-cont">
                <sub-ci-title tit="1963, 그 해를 시작으로" />
                <div class="tit-wrap tit-wrap--sm text-center">
                    <h3 class="tit tit--sm primary--text">- 새마을금고 창립 60주년 특별전 -</h3>
                </div>
                <sub-tit-txt
                    txt="1978년 ‘월간 새마을금고 창간호’를 시작으로 초기 마을금고의 신문을 겸한 공보지에서 오늘날 종합교양지 성격을 지닌 사보에
이르기까지 새마을금고의 성장단면을 회고해볼 수 있는 자료들을 살펴볼 수 있습니다." />
            </div>

            <div class="exhibition-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-1.jpg" alt="표지로 보는 새마을금고" />
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-2.jpg" alt="월간 새마을금고" />
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-3.jpg" alt="월간 새마을금고" />
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-4.jpg" alt="월간 새마을금고" />
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-5.jpg" alt="월간 새마을금고" />
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slideS-6.jpg" alt="1963, 그 해를 시작으로" />
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-l.png" alt="이전 슬라이드" />
                </div>
                <div class="swiper-button-next" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-r.png" alt="다음 슬라이드" />
                </div>
                <div class="swiper-pagination" />
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit tit--sm">특별전시 주요 소장품</h3>
                </div>

                <v-row class="row--sm">
                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/special/collection01.png" alt="" />
                            </template>
                            <template #subject>
                                월간 새마을금고<br />
                                창간호
                            </template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/special/collection02.png" alt="" />
                            </template>
                            <template #subject>만평</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/special/collection03.png" alt="" />
                            </template>
                            <template #subject>새마을금고연합회 전단지</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/special/collection04.png" alt="" />
                            </template>
                            <template #subject>
                                <span>
                                    경제회생 <span class="font-notosans">金</span>모으기 운동<br class="d-none d-lg-block"/>
                                    전단지
                                </span>
                            </template>
                        </image-board-secondary>
                    </v-col>
                </v-row>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCiTitle from "@/components/client/sub/sub-ci-title.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import ImageBoardSecondary from "@/components/dumb/image-board-secondary.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCiTitle,
        SubTitTxt,
        ImageBoardSecondary,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                speed: 800,
                loop: true,
                loopAdditionalSlides : 1,
                pagination: {
                    el: ".exhibition-slide .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".exhibition-slide .swiper-button-next",
                    prevEl: ".exhibition-slide .swiper-button-prev",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
