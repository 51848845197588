var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-img', {
    staticClass: "ma-auto mb-12",
    attrs: {
      "eager": "",
      "max-width": "60",
      "src": "/images/sub/exhibition/ci.png",
      "alt": "MG새마을금고 CI"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s(_vm.tit))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }