<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">주요 소장품 소개</h2>
            </div>

            <v-row class="row--sm mt-12 mt-md-24">
                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/1">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb01.jpg" alt="" />
                        </template>

                        <template #subject> 마을금고 운영대장 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/2">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb02.jpg" alt="" />
                        </template>

                        <template #subject> 연합회 회원가입증 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/3">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb03.jpg" alt="" />
                        </template>

                        <template #subject> 정관 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/4">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb04.jpg" alt="" />
                        </template>

                        <template #subject> 마을금고 등록증 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/5">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb05.jpg" alt="" />
                        </template>

                        <template #subject> 마을금고 규정집 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/6">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb06.jpg" alt="" />
                        </template>

                        <template #subject> 마을금고 기본교재 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/7">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb07.jpg" />
                        </template>

                        <template #subject> 마을금고 관리요원반 교재 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/8">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb08.jpg" />
                        </template>

                        <template #subject> 교육수료기념(수료생 명부) </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/9">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb09.jpg" />
                        </template>

                        <template #subject> 안전기금 가입증서 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/10">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb10.jpg" />
                        </template>

                        <template #subject>
                            <span> 회계기(NCR<span class="font-notosans">社</span>) </span>
                        </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/11">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb11.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject>
                            자기앞수표 발행기념<br class="d-none d-md-block" />
                            순금수표
                        </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/12">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb12.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject> 1960년대 통장 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/13">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb13.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject> 마을금고 운영수기집 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/14">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb14.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject> 월간 새마을금고 창간호 </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/15">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb15.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject>
                            사랑의 좀도리운동<br class="d-none d-md-block" />
                            캠페인 저금통
                        </template>
                    </image-board-tertiary>
                </v-col>

                <v-col cols="6" md="3">
                    <image-board-tertiary to="/collection/major/16">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/collection/major/thumb16.jpg" class="grey lighten-5" />
                        </template>

                        <template #subject> 저축 3조원 달성 포스터 </template>
                    </image-board-tertiary>
                </v-col>
            </v-row>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import ImageBoardTertiary from "@/components/dumb/image-board-tertiary.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        ImageBoardTertiary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
