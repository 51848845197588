var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "기증 · 기탁 안내 ",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("기증 · 기탁 안내")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-sheet', {
    staticClass: "px-20 py-30 pa-lg-40",
    attrs: {
      "color": "#EFF9FF"
    }
  }, [_c('h4', {
    staticClass: "primary--text font-size-24 text-center mb-12 mb-md-18 mb-lg-24"
  }, [_vm._v("MG새마을금고역사관은 단 한 점의 기증도 소중히 생각하며 기다리고 있겠습니다.")]), _c('p', {
    staticClass: "page-text page-text--lg grey--text text-lg-justify line-height-15"
  }, [_vm._v("MG새마을금고역사관은 개인과 단체가 소장하고 계신 새마을금고 관련 자료의 기증을 기다리고 있습니다. 기증해주신 유물은 적합한 온·습도가 유지되는 역사관 수장고에서 안전하고 영구히 보존됩니다. 기증자분(기관)이 원하시면 언제든지 열람이 가능하며, 향후 상설 · 특별전시를 통해 공개 전시함으로써 기증자(기관)의 뜻을 널리 알리고 함께 감상할 수 있는 기회를 갖게 됩니다.")])])], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("기증 절차")])]), _c('v-row', {
    staticClass: "donation-step grey--text",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center",
      "cols": ""
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/collection/donation/1.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--sm mt-12 mt-md-18 mt-lg-24"
  }, [_vm._v(" 기증기탁"), _c('br'), _vm._v(" 의사 접수(신청) ")])], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex justify-center align-ceter"
  }, [_c('icon-chevron', {
    staticClass: "secondary--text",
    attrs: {
      "direction": "right",
      "size": "xx-large"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": ""
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/collection/donation/2.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--sm mt-12 mt-md-18 mt-lg-24"
  }, [_vm._v(" 기증·기탁 안내"), _c('br'), _vm._v(" 및 자료조사 ")])], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex justify-center align-ceter"
  }, [_c('icon-chevron', {
    staticClass: "secondary--text",
    attrs: {
      "direction": "right",
      "size": "xx-large"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": ""
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/collection/donation/3.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--sm mt-12 mt-md-18 mt-lg-24"
  }, [_vm._v("기증 협의")])], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex justify-center align-ceter"
  }, [_c('icon-chevron', {
    staticClass: "secondary--text",
    attrs: {
      "direction": "right",
      "size": "xx-large"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": ""
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/collection/donation/4.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--sm mt-12 mt-md-18 mt-lg-24"
  }, [_vm._v(" 기증·기탁 여부"), _c('br'), _vm._v(" 결정 및 통보 ")])], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex justify-center align-ceter"
  }, [_c('icon-chevron', {
    staticClass: "secondary--text",
    attrs: {
      "direction": "right",
      "size": "xx-large"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "align": "center",
      "cols": ""
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "max-width": "80",
      "src": "/images/sub/collection/donation/5.png",
      "alt": ""
    }
  }), _c('p', {
    staticClass: "page-text page-text--sm mt-12 mt-md-18 mt-lg-24"
  }, [_vm._v(" 유물 인수인계"), _c('br'), _vm._v(" 및 수증처리 ")])], 1)], 1)], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("기증 · 기탁 안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("수집대상 유물")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text"
  }, [_c('p', [_vm._v(" 새마을금고의 역사·문화 관련 문서, 사진, 시청각자료 및 민속생활품 등 전시·연구·교육·보존의 가치가 있는 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 출처가 분명한 자료 ")]), _c('p', {
    staticClass: "primary--text"
  }, [_vm._v("※ 소장경위 혹은 소유권의 출처와 관련하여 논란의 여지가 있는 경우에는 수증이 제한될 수 있습니다.")])])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("유물 기증·기탁 "), _c('br', {
    staticClass: "d-none d-md-block d-lg-none"
  }), _vm._v("신청 및 문의")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text"
  }, [_c('p', [_vm._v("유물 기증·기탁을 원하시는 분은 유물 또는 사진을 가지고 역사관에 직접 방문해 주시거나, 전화·우편·이메일 등을 통해 기증의사를 밝혀주시면 담당자가 확인하여 개별 연락을 드립니다.")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("전화 : "), _c('span', {
    staticClass: "pink--text"
  }, [_vm._v("055-970-0804 (이준현 학예사)")])]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("이메일 : "), _c('span', {
    staticClass: "pink--text"
  }, [_vm._v("junhyun.lee@kfcc.co.kr")])]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("보내실 곳 : (우)52217 경남 산청군 산청읍 친환경로 2742-26 MG새마을금고역사관")]), _c('p', [_c('v-btn', {
    attrs: {
      "href": "/assets/document/유물기증 신청서.hwp",
      "color": "primary",
      "tile": "",
      "x-large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center font-weight-medium"
  }, [_vm._v("유물기증 신청서 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("기증자 예우")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg text-lg-justify grey--text"
  }, [_vm._v("향후 유물을 기증하신 분이나 단체의 뜻을 기리고, 유물의 가치를 되새기기 위해 감사패 증정, 기증 유물 특별전 및 도록 발간, 역사관 행사 초청, 기증자 명예의 전당(가칭)을 조성하여 기증·기탁한 분들에게 감사의 뜻을 전할 계획입니다.")])])], 1)], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }