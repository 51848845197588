var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "전시",
            "tabActive": "상설전시",
            "bg": "/images/sub/exhibition/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', {
    staticClass: "pb-0"
  }, [_c('sub-exhibition-tab', {
    attrs: {
      "tabActive": "3"
    }
  }), _c('sub-ci-title', {
    attrs: {
      "tit": "앞으로 100년"
    }
  }), _c('sub-tit-txt', {
    attrs: {
      "txt": "대한민국 대표 리딩 금융협동조합 MG새마을금고. MG새마을금고는 지역사회를 넘어 국경을 초월한 상생과 나눔을 실천하며\n앞으로의 100년을 준비하고 있습니다. 공동체 정신을 바탕으로 반세기를 굳건히 지켜냈듯이, 글로벌 금융 리더로서의 미래를\n내다볼 수 있는 자리가 되었으면 합니다."
    }
  }), _c('div', {
    staticClass: "exhibition-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-1.jpg",
      "alt": "제3전시관 전경"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-2.jpg",
      "alt": "세계로 향하는 MG새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-3.jpg",
      "alt": "제3전시관 전시물"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-4.jpg",
      "alt": "제3전시관 전시물"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-5.jpg",
      "alt": "MG새마을금고 클래스"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide3-6.jpg",
      "alt": "MG선진경영인"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-l.png",
      "alt": "이전 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-r.png",
      "alt": "다음 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-pagination"
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }