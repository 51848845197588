<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소개" tabActive="공지사항" bg="/images/sub/introduction/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">공지사항</h2>
            </div>
            <notification-list v-if="!$route.params._notice"></notification-list>
            <notification-view v-else></notification-view>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import NotificationList from "@/components/client/center/notifications/notification-list.vue";
import NotificationView from "@/components/client/center/notifications/notification-view.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        NotificationList,
        NotificationView,
    },
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
