<template>
    <ul class="exhibition-tab border-light mb-30 mb-md-44 mb-lg-60">
        <li :class="{ active: tabActive == '1' }">
            <router-link to="/exhibition/hall/1"><p class="page-text">제1전시관</p></router-link>
        </li>
        <li :class="{ active: tabActive == '2' }">
            <router-link to="/exhibition/hall/2"><p class="page-text">제2전시관</p></router-link>
        </li>
        <li :class="{ active: tabActive == '3' }">
            <router-link to="/exhibition/hall/3"><p class="page-text">제3전시관</p></router-link>
        </li>
    </ul>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

export default {
    props: {
        props: {
            tabActive: { type: String, default: "" },
        },
    },
    components: {},
    data: () => {
        return {
            attrs_tabs_common,
            tab: null,
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.exhibition-tab {
    height: 56px;
    display: flex;
    align-items: center;
    li {
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--v-grey-lighten4);
        &:last-child {
            border-right: none;
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            font-weight: 500;
            color: var(--v-grey-lighten1);
        }
        .router-link-active {
            background-color: var(--v-primary-base);
            color: #fff;
        }
    }
}
</style>
