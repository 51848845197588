var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소개",
            "tabActive": "언론보도",
            "bg": "/images/sub/introduction/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("언론보도")])]), _c('div', {
    staticClass: "board-search-wrap"
  }, [_c('v-row', {
    staticClass: "row--xxs search--primary"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "select-box",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "v-input--small w-100 w-md-100px h-40px",
    attrs: {
      "items": _vm.items,
      "item-text": "text",
      "item-value": "value",
      "outlined": "",
      "hide-details": "",
      "light": ""
    },
    model: {
      value: _vm.filter.searchKey,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchKey", $$v);
      },
      expression: "filter.searchKey"
    }
  })], 1), _c('v-col', {
    staticClass: "search-box",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-text-field', {
    staticClass: "v-input--small w-100 w-md-240px",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요.",
      "light": ""
    },
    on: {
      "click": _vm.search
    },
    model: {
      value: _vm.filter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "searchValue", $$v);
      },
      expression: "filter.searchValue"
    }
  })], 1), _c('v-col', {
    staticClass: "search-btn",
    attrs: {
      "cols": "auto"
    },
    on: {
      "click": _vm.search
    }
  }, [_c('v-btn', {
    attrs: {
      "dark": "",
      "color": "grey"
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "row--sm mt-12 mt-md-24"
  }, _vm._l(_vm.boards, function (board, idx) {
    return _c('v-col', {
      key: idx,
      attrs: {
        "cols": "6",
        "md": "3"
      }
    }, [_c('image-board', {
      on: {
        "click": function ($event) {
          return _vm.show(board);
        }
      },
      scopedSlots: _vm._u([{
        key: "image",
        fn: function () {
          return [_c('v-img', {
            staticClass: "grey lighten-5",
            attrs: {
              "cover": "",
              "width": "100%",
              "aspect-ratio": 1 / 1,
              "src": board.thumb
            }
          })];
        },
        proxy: true
      }, {
        key: "subject",
        fn: function () {
          return [_vm._v(" " + _vm._s(board === null || board === void 0 ? void 0 : board.subject) + " ")];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "font-size-14 font-size-md-16 grey--text"
    }, [_vm._v(" " + _vm._s(_vm.$dayjs(board.createdAt).format("YYYY.MM.DD")) + " ")])])], 1);
  }), 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": "5"
    },
    on: {
      "input": function ($event) {
        return _vm.search(true);
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }