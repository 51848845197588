var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "주요 소장품 소개",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-collection-detail', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/collection/major/collection10.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "name",
      fn: function () {
        return [_vm._v("회계기(NCR"), _c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("社")]), _vm._v(")")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("전산화 이전 여·수신업무를 위해 사용하던 기기")];
      },
      proxy: true
    }, {
      key: "year",
      fn: function () {
        return [_vm._v("1980년대")];
      },
      proxy: true
    }, {
      key: "material",
      fn: function () {
        return [_vm._v("플라스틱, 금속")];
      },
      proxy: true
    }, {
      key: "size",
      fn: function () {
        return [_vm._v("가로 53cm, 세로 53cm, 폭 44cm")];
      },
      proxy: true
    }, {
      key: "hall",
      fn: function () {
        return [_vm._v("제2전시관")];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }