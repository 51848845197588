var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "전시",
            "tabActive": "상설전시",
            "bg": "/images/sub/exhibition/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-exhibition-tab', {
    attrs: {
      "tabActive": "2"
    }
  }), _c('sub-ci-title', {
    attrs: {
      "tit": "도약과 신성장"
    }
  }), _c('sub-tit-txt', {
    attrs: {
      "txt": "서민 · 지역 · 국가의 성장을 이끌며 반세기를 이어온 새마을금고. 새마을금고의 내실을 다지기 위한 지난한 과정 속에는 많은 노력과 시도들이 있었습니다. 새마을금고법 제정에 이어 서민금융을 이끌며 도약을 거듭해온 주요 시기별 이슈들을 통해 새마을금고의 사회적 책임과 역할을 알아보고자 합니다."
    }
  }), _c('div', {
    staticClass: "exhibition-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-1.jpg",
      "alt": "CF로 보는 MG 반 세기"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-2.jpg",
      "alt": "새마을금고의 내실화"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-3.jpg",
      "alt": "제2전시관 전시물"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-4.jpg",
      "alt": "제2전시관 전시물"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-5.jpg",
      "alt": "제2전시관 전경"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-6.jpg",
      "alt": "제2전시관 전경"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide2-7.jpg",
      "alt": "제2전시관 전시물"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-l.png",
      "alt": "이전 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-r.png",
      "alt": "다음 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-pagination"
  })], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("제2전시관 주요 소장품")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection2-01.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("새마을금고법(안)")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection2-02.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("안전기금 가입증서")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection2-03.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("1980년대 통장")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection2-04.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("회계기(NCR"), _c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("社")]), _vm._v(") ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }