<template>
    <div align="center">
        <v-img eager class="ma-auto mb-12" max-width="60" src="/images/sub/exhibition/ci.png" alt="MG새마을금고 CI"/>
        <div align="center" class="tit-wrap tit-wrap--sm">
            <h2 class="tit tit--lg">{{ tit }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tit: { type: String, default: "" },
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
