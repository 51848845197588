var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "관람안내",
            "tabActive": "리플렛",
            "bg": "/images/sub/information/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("리플렛")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "border-light",
    attrs: {
      "src": "/images/sub/information/leaflet/leaflet.jpg",
      "alt": "MG새마을금고역사관 과거와 현재, 개인과 공동체를 잇는 KFCC HISTORY MUSEUM"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("MG새마을금고역사관 상설전")])]), _c('v-btn', {
    attrs: {
      "href": "/assets/leaflet/MG새마을금고역사관 상설전_리플렛.pdf",
      "target": "_blank",
      "color": "primary",
      "tile": "",
      "x-large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center font-weight-medium"
  }, [_vm._v("PDF 다운로드 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)])])], 1)], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "border-light",
    attrs: {
      "src": "/images/sub/information/leaflet/special.jpg",
      "alt": "1963, MG새마을금고 창립 60주년 특별전 그 해를 시작으로"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("2023 MG새마을금고역사관 특별전")])]), _c('v-btn', {
    attrs: {
      "href": "/assets/leaflet/2023 새마을금고역사관 특별전_리플렛.pdf",
      "target": "_blank",
      "color": "primary",
      "tile": "",
      "large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center font-weight-medium"
  }, [_vm._v("PDF 다운로드 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)])])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }