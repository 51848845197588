<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소개" tabActive="도서" bg="/images/sub/introduction/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap tit-wrap--lg">
                <h2 class="tit tit--lg text-center">도서</h2>
            </div>

            <!-- <div class="sub-cont">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-img eager src="/images/sub/introduction/book/book01.png" alt="2023 새마을금고 홍보안내책자 60년의 동행, 함께 빛낼 100년 행복한 변화의 중심, 내일이 더 기대되는 MG새마을금고 "/>
                    </v-col>
                    <v-col>
                        <div class="h-100 d-flex align-center">
                            <div>
                                <div class="tit-wrap tit-wrap--sm">
                                    <h3 class="tit">새마을금고 60년사</h3>
                                </div>
                                <div class="mt-12 mt-md-24">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">
                                            <v-btn href="/res/book/2023_새마을금고_홍보안내책자(국문).pdf" target="_blank" color="primary" tile x-large outlined>
                                                <span class="d-flex align-center font-weight-medium">PDF 다운로드 <icon-down class="ml-6 ml-md-14" /></span>
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn color="primary" tile large>
                                                <span class="d-flex align-center font-weight-medium">E - book <icon-arrow-primary direction="right" class="ml-4 ml-md-10" /></span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div> -->

            <div class="sub-cont">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-img eager src="/images/sub/introduction/book/book02.png" alt="고마워요 50년, 함께가요 100년 1963~2013 새마을금고 50년사"/>
                    </v-col>
                    <v-col>
                        <div class="h-100 d-flex align-center">
                            <div>
                                <div class="tit-wrap tit-wrap--sm">
                                    <h3 class="tit">새마을금고 50년사</h3>
                                </div>
                                <div class="mt-12 mt-md-24">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">
                                            <v-btn href="/res/book/새마을금고50년사.pdf" target="_blank" color="primary" tile large outlined>
                                                <span class="d-flex align-center">PDF 다운로드 <icon-down class="ml-6 ml-md-14" /></span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="sub-cont">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-img eager src="/images/sub/introduction/book/book03.png" alt=""/>
                    </v-col>
                    <v-col>
                        <div class="h-100 d-flex align-center">
                            <div>
                                <div class="tit-wrap tit-wrap--sm">
                                    <h3 class="tit">(월간)새마을금고 창간호</h3>
                                </div>
                                <div class="mt-12 mt-md-24">
                                    <v-row class="row--xs">
                                        <v-col cols="auto">
                                            <v-btn href="/res/book/197809_월간새마을금고.pdf" target="_blank" color="primary" tile large outlined>
                                                <span class="d-flex align-center">PDF 다운로드 <icon-down class="ml-6 ml-md-14" /></span>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import ImageBoard from "@/components/dumb/image-board.vue";
import IconDown from "@/components/client/icons/icon-down.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        ImageBoard,
        IconDown,
        IconArrowPrimary,
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { isNotice: false },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: [],

            searchKeys: [{ text: "제목" }, { text: "작성일" }],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
