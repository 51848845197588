<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <sub-collection-detail>
                <template #image>
                    <v-img src="/images/sub/collection/major/collection01.jpg" alt="" />
                </template>
                <template #name>마을금고 운영대장 </template>
                <template #txt> 1969년 경북 예천군 보문면 미호2리 재산 및 마을금고의 운영 관리 대장 </template>
                <template #year> 1969년 </template>
                <template #material> 종이 </template>
                <template #size> 가로 8cm, 세로 26cm </template>
                <template #hall> 제1전시관 </template>
            </sub-collection-detail>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCollectionDetail from "@/components/client/sub/sub-collection-detail.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCollectionDetail,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
</style>
