<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="전시" tabActive="상설전시" bg="/images/sub/exhibition/sub-visual.jpg" />
        </template>
        <sub-section>
            <sub-exhibition-tab tabActive="1" />
            <sub-ci-title tit="반세기 시간의 축적" />
            <sub-tit-txt txt="한 톨, 한 줌의 쌀을 모으는 마음으로 시작된 마을금고. 그 중심에는 이웃과 화합하고 상부상조 정신으로 이루어낸 근검, 절약 그리고 저축이 있습니다. ‘기증품과 영상을 통해’ 더불어 살기 위해 힘을 모으고 금고 운영의 기틀을 마련하기까지의 발자취를 살펴보면서, 한 톨의 쌀로 일구어낸 마을금고의 태동을 되새겨보고자 합니다." />

            <div class="exhibition-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-1.jpg" alt="좀도리통"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-2.jpg" alt="마을금고 공동체운동 태동"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-3.jpg" alt="하둔회관"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-4.jpg" alt="마을금고의 태동"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-5.jpg" alt="제1전시관 전경"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-6.jpg" alt="마을금고연합회 발족과 성장"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide1-7.jpg" alt="구판장, 직장, 학교"/>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-l.png" alt="이전 슬라이드" />
                </div>
                <div class="swiper-button-next" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-r.png" alt="다음 슬라이드" />
                </div>
                <div class="swiper-pagination" />
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit tit--sm">제1전시관 주요 소장품</h3>
                </div>

                <v-row class="row--sm">
                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection1-01.png" alt=""/>
                            </template>
                            <template #subject>정관</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection1-02.png" alt=""/>
                            </template>
                            <template #subject>마을금고 기본교재</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection1-03.png" alt=""/>
                            </template>
                            <template #subject>마을금고 등록증</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection1-04.png" alt=""/>
                            </template>
                            <template #subject> 교육수료기념(수료생 명부) </template>
                        </image-board-secondary>
                    </v-col>
                </v-row>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCiTitle from "@/components/client/sub/sub-ci-title.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import SubExhibitionTab from "@/components/client/sub/sub-exhibition-tab.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import ImageBoardSecondary from "@/components/dumb/image-board-secondary.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCiTitle,
        SubExhibitionTab,
        SubTitTxt,
        UTabFilled,
        ImageBoardSecondary,
        Swiper,
        SwiperSlide,
    },
    data: () => {
        return {
            attrs_tabs_common,
            tab: null,

            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                speed: 800,
                loop: true,
                loopAdditionalSlides : 1,
                pagination: {
                    el: ".exhibition-slide .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".exhibition-slide .swiper-button-next",
                    prevEl: ".exhibition-slide .swiper-button-prev",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.container {
    overflow: visible;
}
</style>
