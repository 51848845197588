<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="전시" tabActive="상설전시" bg="/images/sub/exhibition/sub-visual.jpg" />
        </template>
        <sub-section>
            <sub-exhibition-tab tabActive="2" />
            <sub-ci-title tit="도약과 신성장" />
            <sub-tit-txt txt="서민 · 지역 · 국가의 성장을 이끌며 반세기를 이어온 새마을금고. 새마을금고의 내실을 다지기 위한 지난한 과정 속에는 많은 노력과 시도들이 있었습니다. 새마을금고법 제정에 이어 서민금융을 이끌며 도약을 거듭해온 주요 시기별 이슈들을 통해 새마을금고의 사회적 책임과 역할을 알아보고자 합니다." />

            <div class="exhibition-slide">
                <swiper class="swiper" :options="swiperOptions">
                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-1.jpg" alt="CF로 보는 MG 반 세기"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-2.jpg" alt="새마을금고의 내실화"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-3.jpg" alt="제2전시관 전시물"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-4.jpg" alt="제2전시관 전시물"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-5.jpg" alt="제2전시관 전경"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-6.jpg" alt="제2전시관 전경"/>
                    </swiper-slide>

                    <swiper-slide>
                        <img src="/images/sub/exhibition/hall/slide2-7.jpg" alt="제2전시관 전시물"/>
                    </swiper-slide>
                </swiper>
                <div class="swiper-button-prev" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-l.png" alt="이전 슬라이드" />
                </div>
                <div class="swiper-button-next" tabindex="0">
                    <img src="/images/sub/exhibition/hall/arrow-r.png" alt="다음 슬라이드" />
                </div>
                <div class="swiper-pagination" />
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit tit--sm">제2전시관 주요 소장품</h3>
                </div>

                <v-row class="row--sm">
                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection2-01.png" alt=""/>
                            </template>
                            <template #subject>새마을금고법(안)</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection2-02.png" alt=""/>
                            </template>
                            <template #subject>안전기금 가입증서</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection2-03.png" alt=""/>
                            </template>
                            <template #subject>1980년대 통장</template>
                        </image-board-secondary>
                    </v-col>

                    <v-col cols="6" md="3">
                        <image-board-secondary>
                            <template #image>
                                <v-img cover width="100%" :aspect-ratio="1 / 1" src="/images/sub/exhibition/hall/collection2-04.png" alt=""/>
                            </template>
                            <template #subject>회계기(NCR<span class="font-notosans">社</span>) </template>
                        </image-board-secondary>
                    </v-col>
                </v-row>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCiTitle from "@/components/client/sub/sub-ci-title.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import SubExhibitionTab from "@/components/client/sub/sub-exhibition-tab.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import ImageBoardSecondary from "@/components/dumb/image-board-secondary.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCiTitle,
        SubExhibitionTab,
        SubTitTxt,
        UTabFilled,
        ImageBoardSecondary,
        Swiper,
        SwiperSlide,
    },
    data: () => {
        return {
            attrs_tabs_common,
            tab: null,

            swiperOptions: {
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false,
                },
                speed: 800,
                loop: true,
                loopAdditionalSlides : 1,
                pagination: {
                    el: ".exhibition-slide .swiper-pagination",
                    clickable: true,
                },
                navigation: {
                    nextEl: ".exhibition-slide .swiper-button-next",
                    prevEl: ".exhibition-slide .swiper-button-prev",
                },
            },
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
