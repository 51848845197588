var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "주요 소장품 소개",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-collection-detail', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "src": "/images/sub/collection/major/collection15.jpg",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "name",
      fn: function () {
        return [_vm._v("사랑의 좀도리운동 캠페인 저금통")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_vm._v("사랑의 좀도리운동 캠페인 시, 저금통을 채워 기부에 참여")];
      },
      proxy: true
    }, {
      key: "year",
      fn: function () {
        return [_vm._v("1998년")];
      },
      proxy: true
    }, {
      key: "material",
      fn: function () {
        return [_vm._v("플라스틱")];
      },
      proxy: true
    }, {
      key: "size",
      fn: function () {
        return [_c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("大")]), _vm._v(" 가로 16.3cm, 세로 16.2cm, 폭 13.5cm"), _c('br'), _c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("小")]), _vm._v(" 가로 8.5cm, 세로 8.2cm, 폭 5.7cm ")];
      },
      proxy: true
    }, {
      key: "hall",
      fn: function () {
        return [_vm._v("제2전시관")];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }