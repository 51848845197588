<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="교육 · 행사" tabActive="포토 갤러리" bg="/images/sub/program/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">포토 갤러리</h2>
            </div>

            <div class="board-search-wrap">
                <v-row class="row--xxs search--primary">
                    <v-spacer />
                    <v-col class="select-box" cols="12" md="auto">
                        <v-select v-model="filter.searchKey" :items=items item-text="text" item-value="value" class="v-input--small w-100 w-md-100px h-40px" outlined hide-details light />
                    </v-col>
                    <v-col class="search-box" md="auto">
                        <v-text-field v-model="filter.searchValue" @click="search" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." light />
                    </v-col>
                    <v-col class="search-btn" cols="auto" @click="search">
                        <v-btn dark color="grey">검색</v-btn>
                    </v-col>
                </v-row>
            </div>

            <v-row class="row--sm mt-12 mt-md-24">
                <v-col v-for="board, idx in boards" :key="idx" cols="6" md="3">
                    <image-board @click="show(board)">
                        <template #image>
                            <v-img cover width="100%" :aspect-ratio="1 / 1" :src="board.thumb" class="grey lighten-5" />
                        </template>

                        <template #subject>
                            {{board?.subject}} 
                        </template>

                        <div class="font-size-14 font-size-md-16 grey--text">
                            {{ $dayjs(board.createdAt).format("YYYY.MM.DD") }}
                        </div>
                    </image-board>
                </v-col>
            </v-row>

            <div class="pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search()" />
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import ImageBoard from "@/components/dumb/image-board.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        ImageBoard,
    },
    data() {
        return {
            // mdiAlertCircleOutline,

            boards: [],

            page: 1,
            pageCount: 0,
            limit: 8,

            filter: {
                code : 'gallery',
                searchKey: 'subject',
                searchValue: null
            },

            items: [
                { text: "제목", value: 'subject' }, 
                { text: "내용", value: 'content' }
            ],
        };
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.search()
        },
        async search(){
            let { summary, boards } = await api.v1.boards.gets({ 
                headers:{
                    limit: this.limit,
                    skip: (this.page - 1) * this.limit,
                },
                params: this.filter, 
            });
            this.boards = boards;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        show(board){
            this.$router.push(`/program/gallery/${board._id}`);
        }
    },
};
</script>

<style lang="scss" scoped></style>
