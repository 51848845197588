<template>
    <div class="board-container">
        <div class="board-search-wrap">
            <v-row class="row--xxs search--primary">
                <v-spacer />
                <v-col class="select-box" cols="12" md="auto">
                    <v-select v-model="filter.searchKey" :items="searchKeys" class="v-input--small w-100 w-md-100px h-40px" outlined hide-details placeholder="제목" light />
                </v-col>
                <v-col class="search-box" md="auto">
                    <v-text-field v-model="filter.searchValue" class="v-input--small w-100 w-md-240px" outlined hide-details placeholder="검색어를 입력하세요." light />
                </v-col>
                <v-col class="search-btn" cols="auto" @click="search">
                    <v-btn dark color="grey">검색</v-btn>
                </v-col>
            </v-row>
        </div>

        <table class="board-list w-100 mt-12 mt-md-24 tit tit--sm">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width: 2%" class="board-list__tit"></th>
                    <th style="width: 66%" class="board-list__tit">제목</th>
                    <th style="width: 16%" class="board-list__tit">작성자</th>
                    <th style="width: 16%" class="board-list__tit">작성일</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notification, index) in fixed" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell pa-0">
                        <v-icon>mdi-alert-circle-outline</v-icon>
                    </td>
                    <td class="board-list__txt subject">
                        {{ notification.subject }}
                    </td>
                    <td align="center" class="board-list__txt">관리자</td>
                    <td align="center" class="board-list__txt">
                        {{ $dayjs(notification?.createdAt).format("YYYY-MM-DD") }}
                    </td>
                </tr>
                <tr v-for="(notification, index) in notifications" :key="index" @click="$router.push(`${$route.path}/${notification._id}`)">
                    <td></td>
                    <td class="board-list__txt subject">
                        <!-- <span>{{ (page - 1) * limit + index + 1 }}</span> -->
                        <div class="ellip">
                            <span>
                                {{ notification.subject }}
                            </span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        <div class="ellip">
                            <span>관리자</span>
                        </div>
                    </td>
                    <td align="center" class="board-list__txt">
                        {{ $dayjs(notification?.createdAt).format("YYYY-MM-DD") }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="search(true)" />
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { mdiAlertCircleOutline } from "@mdi/js";
export default {
    components: {},
    props: {
        value: { type: String, default: null },
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: {
                isNotice: false,
                searchKey: "subject",
                searchValue: null,
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 6,

            fixed: [],
            notifications: [],

            searchKeys: [
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            await this.search();
        },
        async search() {
            this.$router.push({
                query: Object.assign({}, this.query, { page: this.page }),
            });

            var { notifications: fixed } = await api.v1.center.notifications.gets({
                headers: {
                    limit: 0,
                },
                params: { isNotice: true },
            });

            this.fixed = fixed;

            var { summary, notifications } = await api.v1.center.notifications.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.notifications = notifications;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
    },
};
</script>

<style lang="scss" scoped>
.board-list__txt.subject::before {
    display: none;
}
// .board-list {
//     border-top: 2px solid var(--v-grey-darken4);
//     &__tit {
//         padding: 12px 0;
//         font-weight: 500;
//     }
//     &__txt {
//         padding: 18px 10px;
//     }
//     tbody {
//         border-top: 1px solid var(--v-grey-lighten4);
//         tr {
//             border-bottom: 1px solid var(--v-grey-lighten4);
//         }
//     }
// }
// ::v-deep {
//     .v-select__selections {
//         padding: 0 !important;
//     }
//     .select-box input::placeholder {
//         color: var(--v-grey-darken4);
//     }
// }
// @media (min-width: 768px) {
//     .board-list {
//         &__tit {
//             padding: 24px 0;
//         }
//         &__txt {
//             padding: 36px 20px;
//         }
//     }
// }
// @media (min-width: 1024px) {
// }
</style>
