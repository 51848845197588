var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "관람안내",
            "tabActive": "오시는 길",
            "bg": "/images/sub/information/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("오시는 길")])]), _c('sub-cont', [_c('v-img', {
    staticClass: "border-light",
    attrs: {
      "src": "/images/sub/information/directions/directions.jpg",
      "alt": "MG새마을금고역사관 약도 : 자세한 내용은 아래를 참고해 주세요."
    }
  })], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("교통안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("주소")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("경상남도 산청군 산청읍 친환경로 2742-26(지리590)")])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("버스")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("산청시외버스터미널 하차 > 도보 10분 > MG새마을금고역사관 도착")])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("주차안내")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('p', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_vm._v("MG새마을금고역사관 주차장 무료")])])], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }