<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <sub-collection-detail>
                <template #image>
                    <v-img src="/images/sub/collection/major/collection06.jpg" alt="" />
                </template>
                <template #name>마을금고 기본교재</template>
                <template #txt>새마을운동의 지도 이념, 마을금고 이론·운영·부기, 가정의례 준칙 등의 내용 수록</template>
                <template #year>1976년</template>
                <template #material>종이</template>
                <template #size>가로 16cm, 세로 23cm</template>
                <template #hall>제1전시관</template>
            </sub-collection-detail>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCollectionDetail from "@/components/client/sub/sub-collection-detail.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCollectionDetail,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>