var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소개",
            "tabActive": "도서",
            "bg": "/images/sub/introduction/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap tit-wrap--lg"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("도서")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "src": "/images/sub/introduction/book/book02.png",
      "alt": "고마워요 50년, 함께가요 100년 1963~2013 새마을금고 50년사"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("새마을금고 50년사")])]), _c('div', {
    staticClass: "mt-12 mt-md-24"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "href": "/res/book/새마을금고50년사.pdf",
      "target": "_blank",
      "color": "primary",
      "tile": "",
      "large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center"
  }, [_vm._v("PDF 다운로드 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)], 1)], 1)])])])], 1)], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    attrs: {
      "eager": "",
      "src": "/images/sub/introduction/book/book03.png",
      "alt": ""
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("(월간)새마을금고 창간호")])]), _c('div', {
    staticClass: "mt-12 mt-md-24"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "href": "/res/book/197809_월간새마을금고.pdf",
      "target": "_blank",
      "color": "primary",
      "tile": "",
      "large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center"
  }, [_vm._v("PDF 다운로드 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)], 1)], 1)])])])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }