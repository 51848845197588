<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="열람·복제 안내" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">열람·복제 안내</h2>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">소장유물 열람·촬영 신청 안내</h3>
                </div>
                <ul class="sub-list">
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">열람 조건</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg grey--text">
                                    <p class="sub-list__dot">MG새마을금고역사관 소장유물을 열람 · 촬영하고자 하는 분들은 요청 공문 및 신청서를 담당자 메일로 제출한 후, 역사관의 허가를 받아야 합니다.</p>
                                    <p class="sub-list__dot">열람 요청 소장유물이 전시, 대여중이거나 상태가 양호하지 못할 경우 열람이 불가합니다.</p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>

                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">신청 절차</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg grey--text">
                                    <p class="sub-list__dot">
                                        ‘소장유물 열람 및 촬영신청서’ 양식을 내려받아 작성한 후 이메일로 제출해 주십시오.<br />
                                        (신청서 제출 후 전화 확인 필요)
                                    </p>
                                    <p class="sub-list__dot">전화 : <span class="pink--text">055-970-0804 (이준현 학예사)</span></p>
                                    <p class="sub-list__dot">이메일 : <span class="pink--text">junhyun.lee@kfcc.co.kr</span></p>
                                    <p>
                                        <v-btn href="/assets/document/소장유물 열람 및 촬영신청서.hwp" color="primary" tile x-large outlined>
                                            <span class="d-flex align-center font-weight-medium">소장유물 열람 및 촬영신청서 <icon-down class="ml-6 ml-md-14" /></span>
                                        </v-btn>
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>

                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">준수사항</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg grey--text">
                                    <p class="sub-list__dot">열람자는 소장품의 안전한 관리를 위해 담당 직원의 지시에 따라 열람하여야 합니다.</p>
                                    <p class="sub-list__dot">열람 시 촬영은 조명기구 없이 개인 휴대용 촬영기구만 사용할 수 있습니다.</p>
                                    <p class="sub-list__dot">
                                        열람‧촬영 시 촬영한 사진의 저작권 및 기타 법적 권리는 MG새마을금고역사관에 있으며, <br class="d-none d-xl-block" />
                                        허가한 목적 이외의 활용 또는 타인의 양도 등 2차 복제는 불허합니다.
                                    </p>
                                    <p class="sub-list__dot">
                                        위 유물 사진을 논문 등 인쇄물 또는 인터넷 공간에 게재할 때 별도 사용허가를 받아야 하며, <br class="d-none d-xl-block" />
                                        MG새마을금고역사관 소장품임을 반드시 명시해야 합니다.
                                    </p>
                                    <p class="sub-list__dot">열람‧촬영 후 작성·제작한 결과물 1부를 역사관에 제출해야 합니다.</p>
                                    <p class="sub-list__dot">
                                        소장품 촬영자가 자료 및 기타 시설이나 기물을 훼손하였을 때에는 역사관장이 정하는 바에 따라 <br class="d-none d-xl-block" />
                                        그 손해를 배상하여야 합니다.
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import IconDown from "@/components/client/icons/icon-down.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        IconDown,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
