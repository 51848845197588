var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "exhibition-tab border-light mb-30 mb-md-44 mb-lg-60"
  }, [_c('li', {
    class: {
      active: _vm.tabActive == '1'
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/exhibition/hall/1"
    }
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v("제1전시관")])])], 1), _c('li', {
    class: {
      active: _vm.tabActive == '2'
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/exhibition/hall/2"
    }
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v("제2전시관")])])], 1), _c('li', {
    class: {
      active: _vm.tabActive == '3'
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/exhibition/hall/3"
    }
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v("제3전시관")])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }