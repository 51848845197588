var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "전시",
            "tabActive": "특별전시",
            "bg": "/images/sub/exhibition/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "sub-cont"
  }, [_c('sub-ci-title', {
    attrs: {
      "tit": "1963, 그 해를 시작으로"
    }
  }), _c('div', {
    staticClass: "tit-wrap tit-wrap--sm text-center"
  }, [_c('h3', {
    staticClass: "tit tit--sm primary--text"
  }, [_vm._v("- 새마을금고 창립 60주년 특별전 -")])]), _c('sub-tit-txt', {
    attrs: {
      "txt": "1978년 ‘월간 새마을금고 창간호’를 시작으로 초기 마을금고의 신문을 겸한 공보지에서 오늘날 종합교양지 성격을 지닌 사보에\n이르기까지 새마을금고의 성장단면을 회고해볼 수 있는 자료들을 살펴볼 수 있습니다."
    }
  })], 1), _c('div', {
    staticClass: "exhibition-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-1.jpg",
      "alt": "표지로 보는 새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-2.jpg",
      "alt": "월간 새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-3.jpg",
      "alt": "월간 새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-4.jpg",
      "alt": "월간 새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-5.jpg",
      "alt": "월간 새마을금고"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slideS-6.jpg",
      "alt": "1963, 그 해를 시작으로"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-l.png",
      "alt": "이전 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-r.png",
      "alt": "다음 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-pagination"
  })], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("특별전시 주요 소장품")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/special/collection01.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 월간 새마을금고"), _c('br'), _vm._v(" 창간호 ")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/special/collection02.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("만평")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/special/collection03.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("새마을금고연합회 전단지")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/special/collection04.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_c('span', [_vm._v(" 경제회생 "), _c('span', {
          staticClass: "font-notosans"
        }, [_vm._v("金")]), _vm._v("모으기 운동"), _c('br', {
          staticClass: "d-none d-lg-block"
        }), _vm._v(" 전단지 ")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }