<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="관람안내" tabActive="오시는 길" bg="/images/sub/information/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">오시는 길</h2>
            </div>
            
            <sub-cont>
                <v-img class="border-light" src="/images/sub/information/directions/directions.jpg" alt="MG새마을금고역사관 약도 : 자세한 내용은 아래를 참고해 주세요."/>
            </sub-cont>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">교통안내</h3>
                </div>
                <ul class="sub-list">
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">주소</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="page-text page-text--lg grey--text">경상남도 산청군 산청읍 친환경로 2742-26(지리590)</p>
                            </v-col>
                        </v-row>
                    </li>
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">버스</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="page-text page-text--lg grey--text">산청시외버스터미널 하차 &gt; 도보 10분 &gt; MG새마을금고역사관 도착</p>
                            </v-col>
                        </v-row>
                    </li>
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">주차안내</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="page-text page-text--lg grey--text">MG새마을금고역사관 주차장 무료</p>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
