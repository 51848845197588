<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="관람안내" tabActive="리플렛" bg="/images/sub/information/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">리플렛</h2>
            </div>

            <div class="sub-cont">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-img class="border-light" src="/images/sub/information/leaflet/leaflet.jpg" alt="MG새마을금고역사관 과거와 현재, 개인과 공동체를 잇는 KFCC HISTORY MUSEUM" />
                    </v-col>
                    <v-col>
                        <div class="h-100 d-flex align-center">
                            <div>
                                <div class="tit-wrap tit-wrap--sm">
                                    <h3 class="tit">MG새마을금고역사관 상설전</h3>
                                </div>
                                <v-btn href="/assets/leaflet/MG새마을금고역사관 상설전_리플렛.pdf" target="_blank" color="primary" tile x-large outlined>
                                    <span class="d-flex align-center font-weight-medium">PDF 다운로드 <icon-down class="ml-6 ml-md-14" /></span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>

            <div class="sub-cont">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-img class="border-light" src="/images/sub/information/leaflet/special.jpg" alt="1963, MG새마을금고 창립 60주년 특별전 그 해를 시작으로" />
                    </v-col>
                    <v-col>
                        <div class="h-100 d-flex align-center">
                            <div>
                                <div class="tit-wrap tit-wrap--sm">
                                    <h3 class="tit">2023 MG새마을금고역사관 특별전</h3>
                                </div>
                                <v-btn href="/assets/leaflet/2023 새마을금고역사관 특별전_리플렛.pdf" target="_blank" color="primary" tile large outlined>
                                    <span class="d-flex align-center font-weight-medium">PDF 다운로드 <icon-down class="ml-6 ml-md-14" /></span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import IconDown from "@/components/client/icons/icon-down.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        IconDown,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
