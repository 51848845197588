<template>
    <div class="sub-head">
        <div class="sub-visual">
            <v-container>
                <h2 class="sub-visual__tit">{{ sh }}</h2>
            </v-container>
            <div class="sub-visual__bg" :style="'background-image:url(' + bg + ');'"></div>
        </div>
        <client-gnb className="sub-tab" :tabActive="tabActive" :sh="sh"></client-gnb>
    </div>
</template>

<script>
import ClientGnb from "@/components/client/inc/client-gnb.vue";

export default {
    props: {
        sh: { type: String, default: "" },
        bg: { type: String, default: "" },
        tabActive: { type: String, default: "" },
    },
    components: {
        ClientGnb,
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped>
/* sub-visual */
.sub-visual {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    height: 200px;
    padding-top: calc(var(--header-body-height) / 2);
}
.sub-visual__tit {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
}
.sub-visual__bg {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .sub-visual {
        height: 300px;
        padding-top: 0;
    }
    .sub-visual__tit {
        font-size: 4rem;
    }
}
@media (min-width: 1024px) {
    .sub-visual {
        height: 400px;
        padding-top: 0;
    }
    .sub-visual__tit {
        font-size: 5.6rem;
    }
}
@media (min-width: 1200px) {
}

::v-deep {
    .sub-tab {
        border-bottom: 1px solid var(--v-grey-lighten3);
        .container {
            max-width: var(--container);
            padding: 0;
        }
        .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
            color: var(--v-grey-base);
        }
        .v-tab {
            transition: all 0.3s;
            .text {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                &::after {
                    content: "";
                    width: 0;
                    height: 4px;
                    background-color: var(--v-primary-base);
                    position: absolute;
                    bottom: 0;
                }
            }
        }
        .v-tab--active {
            color: var(--v-grey-darken4);
            .text {
                &::after {
                    width: 100%;
                }
            }
        }
        .v-tab {
            font-size: var(--tab-font-size);
            position: relative;
            &::after {
                content: "";
                position: absolute;
                right: -0.5px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 16px;
                background-color: var(--v-grey-lighten3);
            }
            &:last-child::after {
                display: none;
            }
        }
        .v-slide-group__content {
            justify-content: center;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
