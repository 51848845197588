var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "전시",
            "tabActive": "상설전시",
            "bg": "/images/sub/exhibition/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('sub-exhibition-tab', {
    attrs: {
      "tabActive": "1"
    }
  }), _c('sub-ci-title', {
    attrs: {
      "tit": "반세기 시간의 축적"
    }
  }), _c('sub-tit-txt', {
    attrs: {
      "txt": "한 톨, 한 줌의 쌀을 모으는 마음으로 시작된 마을금고. 그 중심에는 이웃과 화합하고 상부상조 정신으로 이루어낸 근검, 절약 그리고 저축이 있습니다. ‘기증품과 영상을 통해’ 더불어 살기 위해 힘을 모으고 금고 운영의 기틀을 마련하기까지의 발자취를 살펴보면서, 한 톨의 쌀로 일구어낸 마을금고의 태동을 되새겨보고자 합니다."
    }
  }), _c('div', {
    staticClass: "exhibition-slide"
  }, [_c('swiper', {
    staticClass: "swiper",
    attrs: {
      "options": _vm.swiperOptions
    }
  }, [_c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-1.jpg",
      "alt": "좀도리통"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-2.jpg",
      "alt": "마을금고 공동체운동 태동"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-3.jpg",
      "alt": "하둔회관"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-4.jpg",
      "alt": "마을금고의 태동"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-5.jpg",
      "alt": "제1전시관 전경"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-6.jpg",
      "alt": "마을금고연합회 발족과 성장"
    }
  })]), _c('swiper-slide', [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/slide1-7.jpg",
      "alt": "구판장, 직장, 학교"
    }
  })])], 1), _c('div', {
    staticClass: "swiper-button-prev",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-l.png",
      "alt": "이전 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-button-next",
    attrs: {
      "tabindex": "0"
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/exhibition/hall/arrow-r.png",
      "alt": "다음 슬라이드"
    }
  })]), _c('div', {
    staticClass: "swiper-pagination"
  })], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit tit--sm"
  }, [_vm._v("제1전시관 주요 소장품")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection1-01.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("정관")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection1-02.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("마을금고 기본교재")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection1-03.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v("마을금고 등록증")];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "3"
    }
  }, [_c('image-board-secondary', {
    scopedSlots: _vm._u([{
      key: "image",
      fn: function () {
        return [_c('v-img', {
          attrs: {
            "cover": "",
            "width": "100%",
            "aspect-ratio": 1 / 1,
            "src": "/images/sub/exhibition/hall/collection1-04.png",
            "alt": ""
          }
        })];
      },
      proxy: true
    }, {
      key: "subject",
      fn: function () {
        return [_vm._v(" 교육수료기념(수료생 명부) ")];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }