import { render, staticRenderFns } from "./first.vue?vue&type=template&id=19737bcc&scoped=true&"
import script from "./first.vue?vue&type=script&lang=js&"
export * from "./first.vue?vue&type=script&lang=js&"
import style0 from "./first.vue?vue&type=style&index=0&id=19737bcc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19737bcc",
  null
  
)

export default component.exports