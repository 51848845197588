<template>
    <v-card tile flat class="image-board-card v-card--none-active-bg">
        <v-card tile flat color="#f7f7f7" class="image-board-card__img">
            <slot v-if="this.$slots['image']" name="image" />
        </v-card>

        <div class="py-18px py-md-24px">
            <div class="tit-box d-flex justify-center align-center font-size-18 font-size-md-20 text-truncate-2 text-center page-text page-text--lg">
                <slot name="subject" />
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-board-card {
    border: 1px solid var(--v-grey-lighten3);
    .tit-box {
        padding: 0 10px;
        height: 40px;
        overflow: visible;
    }
}
@media (min-width: 768px) {
    .image-board-card .tit-box {
        height: 54px;
    }
}
</style>
