<template>
    <v-sheet max-width="996" class="ma-auto">
        <p class="page-text page-text--lg line-height-17 text-lg-justify grey--text">
            {{txt}}
        </p>
    </v-sheet>
</template>

<script>
export default {
    props: {
        txt: { type: String, default: "" },
    },
    data: () => {
        return {};
    },
};
</script>

<style lang="scss" scoped></style>
