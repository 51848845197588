var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [this.$slots['image'] ? _vm._t("image") : _vm._e()], 2), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "tit mb-8"
  }, [_vm._t("name")], 2), _c('p', {
    staticClass: "page-text page-text--lg grey--text line-height-17"
  }, [_vm._t("txt")], 2), _c('v-divider', {
    staticClass: "my-12 my-md-18 my-lg-24",
    attrs: {
      "light": ""
    }
  }), _c('div', {
    staticClass: "collection-detail page-text"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "collection-detail__head"
  }, [_vm._v("연도")]), _c('span', {
    staticClass: "grey--text"
  }, [_vm._t("year")], 2)])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "collection-detail__head"
  }, [_vm._v("재질")]), _c('span', {
    staticClass: "grey--text"
  }, [_vm._t("material")], 2)])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "collection-detail__head"
  }, [_vm._v("크기")]), _c('span', {
    staticClass: "grey--text"
  }, [_vm._t("size")], 2)])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "collection-detail__head"
  }, [_vm._v("위치")]), _c('span', {
    staticClass: "grey--text"
  }, [_vm._t("hall")], 2)])])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-end mt-30px mt-md-60px"
  }, [_c('v-btn', {
    staticClass: "w-80px",
    attrs: {
      "color": "primary",
      "tile": "",
      "outlined": "",
      "x-small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("리스트")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }