var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "소장품",
            "tabActive": "열람·복제 안내",
            "bg": "/images/sub/collection/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("열람·복제 안내")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("소장유물 열람·촬영 신청 안내")])]), _c('ul', {
    staticClass: "sub-list"
  }, [_c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("열람 조건")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("MG새마을금고역사관 소장유물을 열람 · 촬영하고자 하는 분들은 요청 공문 및 신청서를 담당자 메일로 제출한 후, 역사관의 허가를 받아야 합니다.")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("열람 요청 소장유물이 전시, 대여중이거나 상태가 양호하지 못할 경우 열람이 불가합니다.")])])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("신청 절차")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v(" ‘소장유물 열람 및 촬영신청서’ 양식을 내려받아 작성한 후 이메일로 제출해 주십시오."), _c('br'), _vm._v(" (신청서 제출 후 전화 확인 필요) ")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("전화 : "), _c('span', {
    staticClass: "pink--text"
  }, [_vm._v("055-970-0804 (이준현 학예사)")])]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("이메일 : "), _c('span', {
    staticClass: "pink--text"
  }, [_vm._v("junhyun.lee@kfcc.co.kr")])]), _c('p', [_c('v-btn', {
    attrs: {
      "href": "/assets/document/소장유물 열람 및 촬영신청서.hwp",
      "color": "primary",
      "tile": "",
      "x-large": "",
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "d-flex align-center font-weight-medium"
  }, [_vm._v("소장유물 열람 및 촬영신청서 "), _c('icon-down', {
    staticClass: "ml-6 ml-md-14"
  })], 1)])], 1)])])], 1)], 1), _c('li', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('h4', {
    staticClass: "tit tit--sm"
  }, [_vm._v("준수사항")])]), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "page-text page-text--lg grey--text"
  }, [_c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("열람자는 소장품의 안전한 관리를 위해 담당 직원의 지시에 따라 열람하여야 합니다.")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("열람 시 촬영은 조명기구 없이 개인 휴대용 촬영기구만 사용할 수 있습니다.")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v(" 열람‧촬영 시 촬영한 사진의 저작권 및 기타 법적 권리는 MG새마을금고역사관에 있으며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 허가한 목적 이외의 활용 또는 타인의 양도 등 2차 복제는 불허합니다. ")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v(" 위 유물 사진을 논문 등 인쇄물 또는 인터넷 공간에 게재할 때 별도 사용허가를 받아야 하며, "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" MG새마을금고역사관 소장품임을 반드시 명시해야 합니다. ")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v("열람‧촬영 후 작성·제작한 결과물 1부를 역사관에 제출해야 합니다.")]), _c('p', {
    staticClass: "sub-list__dot"
  }, [_vm._v(" 소장품 촬영자가 자료 및 기타 시설이나 기물을 훼손하였을 때에는 역사관장이 정하는 바에 따라 "), _c('br', {
    staticClass: "d-none d-xl-block"
  }), _vm._v(" 그 손해를 배상하여야 합니다. ")])])])], 1)], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }