<template>
    <div v-if="notification" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ notification.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <span class="grey--text">{{ $t("common.작성일") }} : {{ notification.createdAt.toDate() }}</span>
                    <!-- <span>작성자 : 관리자</span> &nbsp;&nbsp;&nbsp; -->
                    <!-- <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{ $t("common.작성일") }} : {{ notification.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.조회수')}} : {{ notification.viewCount }}</span>
                        </v-col>
                    </v-row> -->
                </td>
            </tr>
            <tr v-if="notification.upload">
                <td colspan="2">
                    {{ $t("common.attachments") }} : <v-icon>mdi-paperclip</v-icon>
                    <a @click="download(notification.upload, notification.upload.name.split('/').pop())" style="text-decoration: underline">{{ notification.upload.name.split("/").pop() }}</a>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="notification.content.replace(/\n/g, '<br>')"></div>
                </td>
            </tr>
        </table>

        <!-- <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table> -->

        <div class="mt-24px">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group d-flex justify-end">
                <v-btn color="grey lighten-3" tile outlined @click="$router.go(-1)" large>
                    <span class="grey--text">리스트</span>
                </v-btn>
                <!-- <v-btn @click="$router.go(-1)" large color="primary">{{$t("common.list")}}</v-btn> -->
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";

export default {
    components: {},
    data() {
        return {
            notification: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        download,
        async init() {
            try {
                var { notification } = await api.v1.center.notifications.get({ _id: this.$route.params._notice });
                if (notification.upload) notification.upload = await api.getResource(notification.upload);
                this.notification = notification;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>
<style lang="scss" scoped></style>
