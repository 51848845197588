<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소개" tabActive="역사관 소개" bg="/images/sub/introduction/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center primary--text">MG새마을금고역사관 방문을 진심으로 환영합니다.</h2>
            </div>
            <div class="page-text page-text--lg text-lg-justify line-height-17 grey--text">
                <p>MG새마을금고는 1963년 태동 이래, 발상지가 지닌 장소성을 재조명 하고, 새마을금고의 정체성 강화 및 경남지역 최초 금융·경제 역사문화기관으로의 도약을 목적으로 2021년 11월 18일, 경남 산청에 MG새마을금고역사관을 개관하였습니다.</p>
                <p>MG새마을금고역사관은 전국 새마을금고와 개인 기증자로부터 받은 60년대 발행된 통장과 업무 기록물을 비롯하여 전산기기 등 약 500여 점의 유물을 소장하고 있습니다. 이를 통해 태동에서부터 현재까지 새마을금고가 우리나라와 함께 해온 60년 동행의 기념비적인 시간의 흔적들을 살펴볼 수 있습니다.</p>
                <p>나아가 상부상조와 공동체 정신으로 실천해 온 지역사회 공헌활동과 세계적인 상생을 추구하는 국제협력 활동의 노력을 통해 앞으로 100년을 함께 할 새마을금고의 비전을 가슴깊이 느낄 수 있습니다. 앞으로도 각 연령층의 눈높이에 맞는 다양한 전시를 선보이고, 세대별 맞춤형 교육 프로그램을 지속적으로 제공하여 전국 회원과 지역민의 문화 향유를 더욱 확대해나가는 역사관이 되도록 지속적으로 노력하겠습니다. 감사합니다.</p>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
