<template>
    <router-link @click="$emit('click')" class="image-board-card" :to="to">
        <v-card tile flat class="image-board-card__img">
            <slot v-if="this.$slots['image']" name="image" />
        </v-card>

        <div class="py-18px py-md-24px">
            <div class="tit-box d-flex justify-center align-center text-truncate-2 text-center page-text page-text--lg">
                <slot name="subject" />
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        to: { type: String, default: "" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.image-board-card {
    display: block;
    border: 1px solid var(--v-grey-lighten3);
    .tit-box {
        padding: 0 10px;
        height: 40px;
        overflow: visible;
    }
    &__img {
        transition: all 0.3s;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: block;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            opacity: 0;
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
    }
    &:hover,
    &:focus {
        .image-board-card {
            &__img {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .image-board-card .tit-box {
        height: 54px;
    }
}
</style>
