<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <slot v-if="this.$slots['image']" name="image" />
            </v-col>
            <v-col cols="12" md="6">
                <h3 class="tit mb-8">
                    <slot name="name" />
                </h3>
                <p class="page-text page-text--lg grey--text line-height-17">
                    <slot name="txt" />
                </p>

                <v-divider light class="my-12 my-md-18 my-lg-24" />

                <div class="collection-detail page-text">
                    <v-row class="row--xxs">
                        <v-col cols="12">
                            <div class="d-flex">
                                <span class="collection-detail__head">연도</span>
                                <span class="grey--text">
                                    <slot name="year" />
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex">
                                <span class="collection-detail__head">재질</span>
                                <span class="grey--text">
                                    <slot name="material" />
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex">
                                <span class="collection-detail__head">크기</span>
                                <span class="grey--text">
                                    <slot name="size" />
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="d-flex">
                                <span class="collection-detail__head">위치</span>
                                <span class="grey--text">
                                    <slot name="hall" />
                                </span>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <div class="d-flex justify-end mt-30px mt-md-60px">
            <v-btn class="w-80px" color="primary" tile outlined @click="$router.go(-1)" x-small>
                <span class="font-weight-medium">리스트</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.collection-detail {
    &__head {
        display: inline-block;
        width: 40px;
    }
}
@media (min-width: 768px) {
    .collection-detail__head {
        width: 60px;
    }
}
@media (min-width: 1024px) {
    .collection-detail__head {
        width: 72px;
    }
}
.v-divider {
    border-color: var(--v-grey-lighten4);
}
</style>
