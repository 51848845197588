<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="기증 · 기탁 안내 " bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">기증 · 기탁 안내</h2>
            </div>

            <div class="sub-cont">
                <v-sheet color="#EFF9FF" class="px-20 py-30 pa-lg-40">
                    <h4 class="primary--text font-size-24 text-center mb-12 mb-md-18 mb-lg-24">MG새마을금고역사관은 단 한 점의 기증도 소중히 생각하며 기다리고 있겠습니다.</h4>
                    <p class="page-text page-text--lg grey--text text-lg-justify line-height-15">MG새마을금고역사관은 개인과 단체가 소장하고 계신 새마을금고 관련 자료의 기증을 기다리고 있습니다. 기증해주신 유물은 적합한 온·습도가 유지되는 역사관 수장고에서 안전하고 영구히 보존됩니다. 기증자분(기관)이 원하시면 언제든지 열람이 가능하며, 향후 상설 · 특별전시를 통해 공개 전시함으로써 기증자(기관)의 뜻을 널리 알리고 함께 감상할 수 있는 기회를 갖게 됩니다.</p>
                </v-sheet>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">기증 절차</h3>
                </div>
                <v-row justify="space-between" class="donation-step grey--text">
                    <v-col align="center" cols="">
                        <v-img eager max-width="80" src="/images/sub/collection/donation/1.png" alt="" />
                        <p class="page-text page-text--sm mt-12 mt-md-18 mt-lg-24">
                            기증기탁<br />
                            의사 접수(신청)
                        </p>
                    </v-col>
                    <v-col cols="">
                        <div class="h-100 d-flex justify-center align-ceter">
                            <icon-chevron class="secondary--text" direction="right" size="xx-large" />
                        </div>
                    </v-col>
                    <v-col align="center" cols="">
                        <v-img eager max-width="80" src="/images/sub/collection/donation/2.png" alt="" />
                        <p class="page-text page-text--sm mt-12 mt-md-18 mt-lg-24">
                            기증·기탁 안내<br />
                            및 자료조사
                        </p>
                    </v-col>
                    <v-col cols="">
                        <div class="h-100 d-flex justify-center align-ceter">
                            <icon-chevron class="secondary--text" direction="right" size="xx-large" />
                        </div>
                    </v-col>
                    <v-col align="center" cols="">
                        <v-img eager max-width="80" src="/images/sub/collection/donation/3.png" alt="" />
                        <p class="page-text page-text--sm mt-12 mt-md-18 mt-lg-24">기증 협의</p>
                    </v-col>
                    <v-col cols="">
                        <div class="h-100 d-flex justify-center align-ceter">
                            <icon-chevron class="secondary--text" direction="right" size="xx-large" />
                        </div>
                    </v-col>
                    <v-col align="center" cols="">
                        <v-img eager max-width="80" src="/images/sub/collection/donation/4.png" alt="" />
                        <p class="page-text page-text--sm mt-12 mt-md-18 mt-lg-24">
                            기증·기탁 여부<br />
                            결정 및 통보
                        </p>
                    </v-col>
                    <v-col cols="">
                        <div class="h-100 d-flex justify-center align-ceter">
                            <icon-chevron class="secondary--text" direction="right" size="xx-large" />
                        </div>
                    </v-col>
                    <v-col align="center" cols="">
                        <v-img eager max-width="80" src="/images/sub/collection/donation/5.png" alt="" />
                        <p class="page-text page-text--sm mt-12 mt-md-18 mt-lg-24">
                            유물 인수인계<br />
                            및 수증처리
                        </p>
                    </v-col>
                </v-row>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">기증 · 기탁 안내</h3>
                </div>

                <ul class="sub-list">
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">수집대상 유물</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg text-lg-justify grey--text">
                                    <p>
                                        새마을금고의 역사·문화 관련 문서, 사진, 시청각자료 및 민속생활품 등 전시·연구·교육·보존의 가치가 있는 <br class="d-none d-xl-block" />
                                        출처가 분명한 자료
                                    </p>
                                    <p class="primary--text">※ 소장경위 혹은 소유권의 출처와 관련하여 논란의 여지가 있는 경우에는 수증이 제한될 수 있습니다.</p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>

                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">유물 기증·기탁 <br class="d-none d-md-block d-lg-none" />신청 및 문의</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg text-lg-justify grey--text">
                                    <p>유물 기증·기탁을 원하시는 분은 유물 또는 사진을 가지고 역사관에 직접 방문해 주시거나, 전화·우편·이메일 등을 통해 기증의사를 밝혀주시면 담당자가 확인하여 개별 연락을 드립니다.</p>
                                    <p class="sub-list__dot">전화 : <span class="pink--text">055-970-0804 (이준현 학예사)</span></p>
                                    <p class="sub-list__dot">이메일 : <span class="pink--text">junhyun.lee@kfcc.co.kr</span></p>
                                    <p class="sub-list__dot">보내실 곳 : (우)52217 경남 산청군 산청읍 친환경로 2742-26 MG새마을금고역사관</p>
                                    <p>
                                        <v-btn href="/assets/document/유물기증 신청서.hwp" color="primary" tile x-large outlined>
                                            <span class="d-flex align-center font-weight-medium">유물기증 신청서 <icon-down class="ml-6 ml-md-14" /></span>
                                        </v-btn>
                                    </p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>

                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">기증자 예우</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="page-text page-text--lg text-lg-justify grey--text">향후 유물을 기증하신 분이나 단체의 뜻을 기리고, 유물의 가치를 되새기기 위해 감사패 증정, 기증 유물 특별전 및 도록 발간, 역사관 행사 초청, 기증자 명예의 전당(가칭)을 조성하여 기증·기탁한 분들에게 감사의 뜻을 전할 계획입니다.</p>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import IconChevron from "@/components/publish/styles/icons/icon-chevron.vue";
import IconDown from "@/components/client/icons/icon-down.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        IconChevron,
        IconDown,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.donation-step {
    flex-direction: column;
    .icon-svg {
        transform: rotate(-90deg);
    }
}
@media (min-width: 768px) {
    .donation-step {
        flex-direction: initial;
        .icon-svg {
            transform: rotate(180deg);
        }
    }
}
</style>
