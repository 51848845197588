var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "image-board-card v-card--none-active-bg",
    attrs: {
      "tile": "",
      "flat": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-card', {
    staticClass: "image-board-card__img",
    attrs: {
      "tile": "",
      "flat": ""
    }
  }, [this.$slots['image'] ? _vm._t("image") : _vm._e()], 2), _c('div', {
    staticClass: "pa-18px pa-md-24px"
  }, [_c('div', {
    staticClass: "mb-4px mb-md-10px"
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-20 text-truncate-2"
  }, [_vm._t("subject")], 2)]), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }