<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="관람안내" tabActive="관람안내" bg="/images/sub/information/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">관람안내</h2>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">관람안내</h3>
                </div>
                <ul class="sub-list">
                    <li>
                        <v-row no-gutters align="center">
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">관람시간</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <div class="page-text page-text--lg grey--text">
                                    <p>· 월요일 ~ 토요일 : 오전 10시 ~ 오후 6시</p>
                                    <p class="primary--text ml-8 ml-lg-12">※ 입장마감 : 오후 5시</p>
                                </div>
                            </v-col>
                        </v-row>
                    </li>
                    <li>
                        <v-row no-gutters>
                            <v-col cols="12" md="3">
                                <h4 class="tit tit--sm">휴관일</h4>
                            </v-col>
                            <v-col cols="12" md="9">
                                <p class="page-text page-text--lg grey--text">· 매주 일요일, 법정 공휴일, 근로자의 날</p>
                            </v-col>
                        </v-row>
                    </li>
                </ul>
            </div>

            <div class="sub-cont">
                <div id="group">
                    <div class="tit-wrap tit-wrap--sm">
                        <h3 class="tit">단체관람안내</h3>
                    </div>
                    <ul class="sub-list">
                        <li>
                            <v-row no-gutters>
                                <v-col cols="12" md="3">
                                    <h4 class="tit tit--sm">단체 관람 예약</h4>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <div class="page-text page-text--lg grey--text">
                                        <p class="red--text ml-8 ml-lg-12">※ 토요일 단체관람 불가</p>
                                        <p>· 20명 이상 학생(초 · 중 · 고) 및 성인</p>
                                        <p>· 당일 예약 불가, 최소 7일 전 예약 필수 (미예약 단체는 전시 관람이 제한될 수 있음)</p>
                                        <p>· 문의 : 055-970-0800</p>
                                    </div>
                                </v-col>
                            </v-row>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">관람 유의사항</h3>
                </div>

                <div class="border-light pa-12 pa-md-18 pa-lg-24">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/1.png" alt="" />
                                <p class="page-text page-text--lg">박물관의 모든 공간은 금연구역입니다.</p>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/2.png" alt="" />
                                <p class="page-text page-text--lg">안내견 이외의 애완동물은 출입할 수 없습니다.</p>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/3.png" alt="" />
                                <p class="page-text page-text--lg line-height-17">
                                    전시물을 건드리거나 손상을 입힐 수 있는 행위는<br class="d-none d-xl-block" />
                                    삼가주십시오. (체험전시물 제외)
                                </p>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/4.png" alt="" />
                                <p class="page-text page-text--lg">전시관 내에 음식물을 반입할 수 없습니다.</p>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/5.png" alt="" />
                                <p class="page-text page-text--lg line-height-17">
                                    전시관 입장 전에 휴대전화는 전원을 꺼주시거나 <br class="d-none d-xl-block" />
                                    진동으로 전환하여 주십시오.
                                </p>
                            </div>
                        </v-col>

                        <v-col cols="12" md="6">
                            <div class="d-flex align-center">
                                <v-img eager max-width="80" class="mr-6 mr-md-12" src="/images/sub/information/information/6.png" alt="" />
                                <p class="page-text page-text--lg line-height-17">
                                    플래쉬, 삼각대 등을 이용한 사진촬영, 상업적 용도의 <br class="d-none d-xl-block" />
                                    촬영은 금지입니다.
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
#group {
    padding-top: var(--header-body-height);
    margin-top: calc(-1 * var(--header-body-height));
}
</style>
