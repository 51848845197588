<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소개" tabActive="언론보도" bg="/images/sub/introduction/sub-visual.jpg" />
        </template>

        <sub-section>
            <table class="board-view">
                <tr>
                    <th colspan="2">{{ board.subject }}</th>
                </tr>
                <tr>
                    <td colspan="2">
                        <span class="grey--text">작성일: {{ $dayjs(board.createdAt).format("YYYY-MM-DD") }}</span>
                    </td>
                </tr>
                <tr v-if="board?.upload">
                    <td colspan="2">
                        {{ $t("common.attachments") }} : <v-icon>mdi-paperclip</v-icon>
                        <a @click="download(board.files[0], board.files[0].name.split('/').pop())" style="text-decoration: underline">{{ board.files[0].fileName }}</a>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="content_box">
                        <div v-html="board.content"></div>
                    </td>
                </tr>
            </table>

            <div class="mt-24px">
                <div class="v-btn--group d-flex justify-end">
                    <v-btn color="grey lighten-3" tile outlined @click="$router.go(-1)" large>
                        <span class="grey--text">리스트</span>
                    </v-btn>
                </div>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import api from "@/api"
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import download from "downloadjs";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
    },
    data() {
        return {
            board: null
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._coverage });
            this.board = board;
        },
        download
    },
};
</script>

<style lang="scss" scoped></style>
