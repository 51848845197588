var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "sh": "관람안내",
            "tabActive": "시설안내",
            "bg": "/images/sub/information/sub-visual.jpg"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit tit--lg text-center"
  }, [_vm._v("시설안내")])]), _c('div', {
    staticClass: "sub-cont"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("건물평면도")])]), _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "border-light",
    attrs: {
      "src": "/images/sub/information/facility/12f.jpg",
      "alt": "1, 2층 건물평면도 : 강당, 제1전시관, 제2전시관, 무인카페, 화장실, 수유실, 안내데스크가 있습니다. 안내데스크는 출입구를 기준으로 오른쪽에 있습니다."
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-img', {
    staticClass: "border-light",
    attrs: {
      "src": "/images/sub/information/facility/3f.jpg",
      "alt": "3층 건물평면도 : 옥상정원, 제3전시관이 있습니다. 제3전시관 안쪽에 주제영상관이 있습니다."
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "sub-cont"
  }, [_c('v-tabs', _vm._b({
    staticClass: "v-tabs--density-compact",
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, 'v-tabs', Object.assign({}, _vm.attrs_tabs_common, _vm.$attrs), false), [_c('u-tab-filled', {
    staticClass: "w-20"
  }, [_vm._v("강당")]), _c('u-tab-filled', {
    staticClass: "w-20"
  }, [_vm._v("무인카페")]), _c('u-tab-filled', {
    staticClass: "w-20"
  }, [_vm._v("1전시관")]), _c('u-tab-filled', {
    staticClass: "w-20"
  }, [_vm._v("2전시관")]), _c('u-tab-filled', {
    staticClass: "w-20"
  }, [_vm._v("3전시관")])], 1), _c('div', {
    staticClass: "mt-30 mt-md-44 mt-lg-60"
  }, [_c('v-tabs-items', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "eager": "",
      "transition": false
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/information/facility/1.jpg",
      "alt": "강당 사진"
    }
  })]), _c('v-tab-item', {
    attrs: {
      "eager": "",
      "transition": false
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/information/facility/2.jpg",
      "alt": "무인카페 사진"
    }
  })]), _c('v-tab-item', {
    attrs: {
      "eager": "",
      "transition": false
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/information/facility/3.jpg",
      "alt": "제1전시관 사진"
    }
  })]), _c('v-tab-item', {
    attrs: {
      "eager": "",
      "transition": false
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/information/facility/4.jpg",
      "alt": "제2전시관 사진"
    }
  })]), _c('v-tab-item', {
    attrs: {
      "eager": "",
      "transition": false
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/sub/information/facility/5.jpg",
      "alt": "제3전시관 사진"
    }
  })])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }