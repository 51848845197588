<template>
    <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" class="icon-svg icon-down">
        <g>
            <path d="M8.46959 13.2803C8.46959 13.2803 8.47029 13.2806 8.47029 13.281C8.48752 13.2982 8.50545 13.3144 8.52443 13.3298C8.53322 13.3369 8.54236 13.3432 8.55115 13.3499C8.5617 13.358 8.57225 13.3661 8.58314 13.3734C8.59369 13.3805 8.60494 13.3868 8.61584 13.3935C8.62604 13.3995 8.63553 13.4058 8.64607 13.4114C8.65732 13.4174 8.66893 13.4227 8.68018 13.4279C8.69107 13.4329 8.70162 13.4385 8.71252 13.443C8.72377 13.4476 8.73502 13.4515 8.74627 13.4554C8.75822 13.4596 8.76982 13.4641 8.78178 13.4677C8.79303 13.4712 8.80463 13.4736 8.81588 13.4764C8.82818 13.4796 8.84049 13.4831 8.85279 13.4856C8.86615 13.488 8.87916 13.4898 8.89252 13.4916C8.90342 13.493 8.91432 13.4951 8.92521 13.4965C8.97443 13.5014 9.024 13.5014 9.07322 13.4965C9.08447 13.4954 9.09502 13.4933 9.10592 13.4916C9.11928 13.4898 9.13264 13.488 9.14564 13.4856C9.1583 13.4831 9.17025 13.4796 9.18256 13.4764C9.19381 13.4736 9.20541 13.4712 9.21666 13.4677C9.22861 13.4641 9.24057 13.4596 9.25217 13.4554C9.26342 13.4515 9.27467 13.4476 9.28592 13.443C9.29682 13.4385 9.30736 13.4332 9.31826 13.4279C9.32986 13.4227 9.34111 13.4174 9.35236 13.4114C9.36256 13.4058 9.3724 13.3995 9.3826 13.3935C9.3935 13.3868 9.40475 13.3808 9.41529 13.3734C9.42654 13.3661 9.43674 13.3576 9.44728 13.3499C9.45643 13.3432 9.46557 13.3369 9.474 13.3298C9.49299 13.3144 9.51092 13.2982 9.52814 13.281C9.52814 13.281 9.52885 13.2806 9.52885 13.2803L13.279 9.53016C13.5718 9.2373 13.5718 8.76234 13.279 8.46949C12.9861 8.17664 12.5112 8.17664 12.2183 8.46949L9.74857 10.9392V0.749883C9.74857 0.335742 9.41283 0 8.99869 0C8.58455 0 8.24881 0.335742 8.24881 0.749883V10.9392L5.77908 8.46949C5.48623 8.17664 5.01127 8.17664 4.71842 8.46949C4.42557 8.76234 4.42557 9.2373 4.71842 9.53016L8.46853 13.2803H8.46959Z" />
            <path d="M17.2501 12.75C16.836 12.75 16.5002 13.0857 16.5002 13.4999V16.4998H1.50012V13.4999C1.50012 13.0857 1.16437 12.75 0.750234 12.75C0.336094 12.75 0 13.0857 0 13.4999V17.25C0 17.6641 0.335742 17.9999 0.749883 17.9999H17.2501C17.6643 17.9999 18 17.6641 18 17.25V13.4999C18 13.0857 17.6643 12.75 17.2501 12.75Z" />
        </g>
    </svg>
</template>

<script>
export default {
    props: {},
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.icon-down{
    width: 18px;
    height: 18px;
}
</style>
