<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="교육 · 행사" tabActive="교육 안내 및 소개" bg="/images/sub/program/sub-visual.jpg" />
        </template>

        <sub-section>
            <div class="tit-wrap">
                <h2 class="tit tit--lg text-center">교육 안내 및 소개</h2>
            </div>
            <sub-tit-txt txt="MG새마을금고역사관은 사회공헌활동의 일환으로 어린이, 청소년 대상 화폐, 용돈 관리 등을 배울 수 있는 교육·체험 프로그램을 제공하고, 금융소외계층 대상 금융교육, 보이스피싱 예방교육 등을 실시하여 지역민과 함께하는 금융을 적극 실천하고 있습니다." />

            <div class="sub-cont">
                <v-img src="/images/sub/program/education/img.png" alt="" />
            </div>

            <div class="sub-cont">
                <div class="tit-wrap tit-wrap--sm">
                    <h3 class="tit">교육 안내</h3>
                </div>
                <ul class="sub-list">
                    <li class="px-0 d-flex align-center">
                        <div class="icon-box" align="center">
                            <v-img eager max-width="120" src="/images/sub/program/education/child-icon.png" alt=""/>
                            <h4 class="tit tit--sm mt-6 mt-md-8 mt-lg-10">어린이 금융 교육</h4>
                        </div>
                        <p class="page-text page-text--lg text-lg-justify grey--text line-height-17">성장기 유아 및 어린이들이 돈의 개념, 은행의 역할, 용돈 관리 등을 배울 수 있는 금융 교육 프로그램입니다. 전문 강사들이 유아 및 어린이 대상 맞춤형 교육을 진행하여, 다양한 체험을 통해 쉽고 재미있게 금융에 접근하도록 도와줍니다.</p>
                    </li>

                    <li class="px-0 d-flex align-center">
                        <div class="icon-box" align="center">
                            <v-img eager max-width="120" src="/images/sub/program/education/teenager-icon.png" alt=""/>
                            <h4 class="tit tit--sm mt-6 mt-md-8 mt-lg-10">청소년 금융 교육</h4>
                        </div>
                        <p class="page-text page-text--lg text-lg-justify grey--text line-height-17">본 교육은 용돈 씀씀이가 커지는 청소년이 알아야 할 합리적인 용돈 사용법, 저축 및 투자, 신용점수 등의 금융 지식을 제공합니다. 또한 미래를 준비하고 꿈을 향해 전진해야 하는 청소년 시기에 도움이 될 수 있는 금융·경제·진로 교육을 진행합니다.</p>
                    </li>

                    <li class="px-0 d-flex align-center">
                        <div class="icon-box" align="center">
                            <v-img eager max-width="120" src="/images/sub/program/education/senior-icon.png" alt=""/>
                            <h4 class="tit tit--sm mt-6 mt-md-8 mt-lg-10">시니어 금융 교육</h4>
                        </div>
                        <p class="page-text page-text--lg text-lg-justify grey--text line-height-17">전문 강사가 다양한 시청각 자료를 활용하여 시니어를 대상으로 보이스피싱 예방교육을 선보입니다. 이에 더해 모바일 뱅킹 등 디지털 금융과 노후대비 금융상품을 소개하는 금융·경제 지식 관련 교육 또한 실시하고 있습니다.</p>
                    </li>
                </ul>
            </div>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubTitTxt from "@/components/client/sub/sub-tit-txt.vue";
import ImageBoard from "@/components/dumb/image-board.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        ImageBoard,
        SubTitTxt,
    },
    data() {
        return {
            mdiAlertCircleOutline,

            filter: { isNotice: false },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            fixed: [],
            notifications: [],

            searchKeys: [{ text: "제목" }, { text: "작성일" }],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
