<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="소장품" tabActive="주요 소장품 소개" bg="/images/sub/collection/sub-visual.jpg" />
        </template>

        <sub-section>
            <sub-collection-detail>
                <template #image>
                    <v-img src="/images/sub/collection/major/collection14.jpg" alt="" />
                </template>
                <template #name>월간 새마을금고 창간호</template>
                <template #txt>월간 새마을금고 창간호</template>
                <template #year>1987년</template>
                <template #material>종이</template>
                <template #size>가로 14.7cm, 세로 20.8cm</template>
                <template #hall>수장고</template>
            </sub-collection-detail>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import SubCollectionDetail from "@/components/client/sub/sub-collection-detail.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        SubSection,
        SubCollectionDetail,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>